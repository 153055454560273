<template>
  <div
    class="appointments__upcoming"
    :class="{ appointments__nearest: upcomingAppointments }"
  >
    <router-link
      :to="/appointment/ + appointment.code"
      class="appointments__link d-flex flex-column justify-content-between"
    >
      <div class="row d-flex justify-content-between">
        <div class="col-8">
          <div class="row m-0 appointments__title">
            {{ appointment.service_name }}
          </div>
        </div>
        <div class="col-3">
          <div class="row m-0 appointments__data">
            <data-day :data="appointment.time" />
          </div>
          <div class="row m-0 appointments__time">
            <data-formatted date-format="HH:mm" :data="appointment.time" />
          </div>
        </div>
      </div>
      <div class="row justify-content-between appointments__information">
        <div class="w-auto">
          <div class="appointments__type">
            {{ appointment.consultation_name }}
          </div>
          <div class="appointments__durations">
            {{ $t("duration") }}
            <duration-time :data="appointment.consultation_duration" />
          </div>
          <div class="appointments__type">
            €
            <span
              v-if="!!appointment.employee_price && appointment.expert_code"
              >{{ appointment.employee_price }}</span
            >
            <span
              v-if="!!appointment.expert_income && appointment.employee_code"
              >{{ appointment.expert_income }}</span
            >
            <span v-if="!!appointment.consultation_expert_income">{{
              appointment.consultation_expert_income
            }}</span>
          </div>
        </div>
        <div class="row m-0 appointments__id">
          {{ appointment.code }}
        </div>
      </div>
      <div class="row d-flex justify-content-between align-content-lg-center">
        <div class="col-10">
          <div class="row d-flex align-items-lg-center">
            <div class="w-auto">
              <avatar
                v-if="appointment.expert_code"
                width="80px"
                height="80px"
                user-role="experts"
                size="100"
                :user-code="appointment.expert_code"
                :image-name="appointment.expert_image"
              />
              <avatar
                v-if="appointment.employee_code"
                width="80px"
                height="80px"
                user-role="employees"
                size="100"
                :user-code="appointment.employee_code"
                :image-name="appointment.employee_image"
              />
            </div>
            <div class="w-auto">
              <div class="row appointments__mentor-name">
                <short-name
                  v-if="appointment.expert_first_name"
                  :name="
                    appointment.expert_first_name +
                    ' ' +
                    appointment.expert_last_name
                  "
                />
                <short-name
                  v-if="appointment.employee_first_name"
                  :name="
                    appointment.employee_first_name +
                    ' ' +
                    appointment.employee_last_name
                  "
                />
              </div>
              <div class="row appointments__position">
                {{ appointment.expert_position }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex align-items-lg-end justify-content-lg-end">
          <arrow-think :nearest="nearest" />
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import DataDay from "@/components/components-helpers/DataDay";
import DurationTime from "@/components/components-helpers/DurationTime";
import Avatar from "@/components/UI/images/Avatar";
import ShortName from "@/components/components-helpers/ShortName";
import ArrowThink from "@/components/UI/icons/arrows/ArrowThink";
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  components: {
    ArrowThink,
    ShortName,
    Avatar,
    DataFormatted,
    DurationTime,
    DataDay,
  },
  props: {
    appointment: Object,
    upcomingAppointments: {
      type: Boolean,
      default: false,
    },
    nearest: {
      type: Boolean,
      default: false,
    },
  },
  name: "appointments",
};
</script>

<style></style>
