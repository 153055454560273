<template>
  <main-layout>
    <employee-appointment-history v-if="loginUser.role_id === 4" />
    <company-appointment-history v-if="loginUser.role_id === 3" />
    <expert-appointment-history v-if="loginUser.role_id === 2" />
  </main-layout>
</template>

<script>
import { mapGetters } from "vuex";
import MainLayout from "@/layouts/MainLayout";
import ExpertAppointmentHistory from "@/components/pages-templates/history/ExpertAppointmentHistory";
import EmployeeAppointmentHistory from "@/components/pages-templates/history/EmployeeAppointmentHistory";
import CompanyAppointmentHistory from "@/components/pages-templates/history/CompanyAppointmentHistory";

export default {
  name: "AppointmentHistoryPage",
  components: {
    CompanyAppointmentHistory,
    EmployeeAppointmentHistory,
    ExpertAppointmentHistory,
    MainLayout,
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
