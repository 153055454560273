<template>
  <h1>{{ $t("historyAppointmentPage") }}</h1>
  <div v-if="haveAppointments">
    <div class="appointmentHistoryPage__subtitle">
      {{ $t("employeeHomePageTitle") }}:
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="employee__recent-sort">
        <div class="mr-15">{{ $t("employeeHomePageSort") }}</div>
        <base-button
          @click="sortBy('date', 'upcomingAppointments')"
          :active="activeSort.upcomingAppointments.date"
          :sort="true"
        >
          {{ $t("filterByDate") }}
        </base-button>

        <base-button
          @click="sortBy('expert', 'upcomingAppointments')"
          :active="activeSort.upcomingAppointments.expert"
          :sort="true"
        >
          {{ $t("filterByExpert") }}
        </base-button>
        <base-button
          @click="sortBy('service', 'upcomingAppointments')"
          :active="activeSort.upcomingAppointments.service"
          :sort="true"
        >
          {{ $t("filterByService") }}
        </base-button>
        <div class="ml-5 cursor-pointer" @click="direction('appointments')">
          <sort-icon :active="directions.appointments === 'DESC'" />
        </div>
      </div>
      <div class="response-switcher">
        <div class="me-10">
          {{ $t("view") }}
        </div>
        <table-view-icon
          class="me-10 cursor-pointer"
          :active="view.upcoming.table"
          @click="changeView('table', 'upcoming')"
        />
        <cart-view-icon
          class="me-10 cursor-pointer"
          :active="view.upcoming.cart"
          @click="changeView('cart', 'upcoming')"
        />
      </div>
    </div>
    <div v-if="view.upcoming.cart" class="row employee__appointmentsTable">
      <div
        v-for="appointment in appointment.upcomingAppointments"
        class="col-lg-6"
      >
        <appointments :key="appointment.id" :appointment="appointment" />
      </div>
    </div>
    <div v-if="view.upcoming.table" class="employee__appointmentsTable">
      <employee-appointments-table
        v-for="appointment in appointment.upcomingAppointments"
        :information="appointment"
      />
    </div>
    <div
      v-if="appointment.upcomingAppointments.length === viewLimit.upcoming"
      class="w-max-content m-auto"
    >
      <base-button @click="loadMore('upcoming')" :load="true"
        >{{ $t("loadMore") }}
      </base-button>
    </div>
  </div>
  <div v-else class="row employee__appointments mb-30">
    <div class="col-12 col-lg-6">
      <div class="templateHaveContent">
        <div>
          {{ $t("employeeHomePageTemplateText") }}
          <span class="weight-500">{{ $t("headerCalendarBtn") }}</span>
          {{ $t("employeeHomePageTemplateText2") }}
        </div>
        <base-button link="/calendar" :calendar="true">
          <template #icon>
            <calendar-icon />
          </template>
          {{ $t("headerCalendarBtn") }}
        </base-button>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="templateNoContent"></div>
    </div>
  </div>
  <div v-if="haveRecentAppointments">
    <div class="appointmentHistoryPage__subtitle">
      {{ $t("employeeHomePageRecent") }}:
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="employee__recent-sort">
        <div class="mr-15">{{ $t("employeeHomePageSort") }}</div>
        <base-button
          @click="sortBy('date', 'recentAppointments')"
          :active="activeSort.recentAppointments.date"
          :sort="true"
        >
          {{ $t("filterByDate") }}
        </base-button>
        <base-button
          @click="sortBy('expert', 'recentAppointments')"
          :active="activeSort.recentAppointments.expert"
          :sort="true"
        >
          {{ $t("filterByExpert") }}
        </base-button>
        <base-button
          @click="sortBy('service', 'recentAppointments')"
          :active="activeSort.recentAppointments.service"
          :sort="true"
        >
          {{ $t("filterByService") }}
        </base-button>
        <div class="ml-5 cursor-pointer" @click="direction('history')">
          <sort-icon :active="directions.history === 'DESC'" />
        </div>
      </div>
      <div class="response-switcher">
        <div class="me-10">
          {{ $t("view") }}
        </div>
        <table-view-icon
          class="me-10 cursor-pointer"
          :active="view.recent.table"
          @click="changeView('table', 'recent')"
        />
        <cart-view-icon
          class="me-10 cursor-pointer"
          :active="view.recent.cart"
          @click="changeView('cart', 'recent')"
        />
      </div>
    </div>
    <div v-if="view.recent.cart" class="row appointmentHistoryPage">
      <div
        v-for="appointment in appointment.recentAppointments"
        class="col-12 col-md-6 col-xxl-3"
      >
        <recent-appointments
          :history="true"
          :recent-appointment="appointment"
        />
      </div>
    </div>
    <div v-if="view.recent.table" class="employee__appointmentsTable">
      <employee-recent-appointments-table
        v-for="appointment in appointment.recentAppointments"
        :information="appointment"
      />
    </div>
    <div
      v-if="appointment.recentAppointments.length === viewLimit.history"
      class="w-max-content m-auto"
    >
      <base-button @click="loadMore('history')" :load="true"
        >{{ $t("loadMore") }}
      </base-button>
    </div>
    <div v-if="false">
      <pagination :items="appointment.recentAppointments" />
    </div>
  </div>
  <div v-else class="row appointmentHistoryPage">
    <div class="col-12 col-md-6 col-xxl-3">
      <div class="templateRecent"></div>
    </div>
    <div class="col-12 col-md-6 col-xxl-3">
      <div class="templateRecent"></div>
    </div>
    <div class="col-12 col-md-6 col-xxl-3">
      <div class="templateRecent"></div>
    </div>
    <div class="col-12 col-md-6 col-xxl-3">
      <div class="templateRecent"></div>
    </div>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import RecentAppointments from "@/components/elements/appointment/RecentAppointments";
import BaseButton from "@/components/UI/buttons/BaseButton";
import CartViewIcon from "@/components/UI/icons/buttonIcons/CartViewIcon.vue";
import TableViewIcon from "@/components/UI/icons/TableViewIcon";
import Appointments from "@/components/elements/appointment/Appointments";
import CalendarIcon from "@/components/UI/icons/CalendarIcon";
import EmployeeAppointmentsTable from "@/components/elements/appointment/EmployeeAppointmentsTable";
import EmployeeRecentAppointmentsTable from "@/components/elements/appointment/EmployeeRecentAppointmentsTable";
import SortIcon from "@/components/UI/icons/filterIcons/SortIcon";
import Pagination from "@/components/elements/Pagination";

export default {
  name: "EmployeeAppointmentHistory",
  components: {
    Pagination,
    SortIcon,
    EmployeeRecentAppointmentsTable,
    EmployeeAppointmentsTable,
    CalendarIcon,
    Appointments,
    TableViewIcon,
    CartViewIcon,
    BaseButton,
    RecentAppointments,
    MainLayout,
  },
  data() {
    return {
      view: {
        upcoming: {
          cart: false,
          table: true,
        },
        recent: {
          cart: false,
          table: true,
        },
      },
      appointment: {
        upcomingAppointments: {},
        recentAppointments: {},
      },
      activeSort: {
        upcomingAppointments: {
          date: true,
          expert: false,
          service: false,
        },
        recentAppointments: {
          date: true,
          expert: false,
          service: false,
        },
      },
      appointmentsSort: "date",
      directions: {
        history: "ASC",
        appointments: "ASC",
      },
      historySort: "date",
      viewLimit: {
        history: 10,
        upcoming: 10,
      },
    };
  },
  mounted() {
    this.getHistory();
    this.getAppointments();
  },
  methods: {
    direction(key) {
      this.directions[key] = this.directions[key] === "ASC" ? "DESC" : "ASC";
      if (key === "appointments") {
        this.getAppointments();
      }
      if (key === "history") {
        this.getHistory(this.historySort, this.directions[key]);
      }
    },
    sortBy(key, type) {
      for (let i in this.activeSort[type]) {
        this.activeSort[type][i] = i === key;
      }
      if (type === "upcomingAppointments") {
        this.appointmentsSort = key;
        this.getAppointments(this.appointmentsSort);
        return;
      }
      if (type === "recentAppointments") {
        this.historySort = key;
        this.getHistory(this.historySort);
      }
    },
    changeView(view, type) {
      let viewValue = {
        cart: false,
        table: false,
      };
      viewValue[view] = true;
      this.view[type] = viewValue;
    },
    getAppointments() {
      this.$http
        .get("/appointments/upcoming", {
          params: {
            limit: this.viewLimit.upcoming,
            sortBy: this.appointmentsSort,
            direction: this.directions.appointments,
          },
        })
        .then(({ data }) => {
          this.appointment.upcomingAppointments = data.body;
        });
    },
    getHistory() {
      this.$http
        .get("/appointments/history", {
          params: {
            limit: this.viewLimit.history,
            sortBy: this.historySort,
            direction: this.directions.history,
          },
        })
        .then(({ data }) => {
          this.appointment.recentAppointments = data.body;
        });
    },
    loadMore(key) {
      this.viewLimit[key] += 10;
      if (key === "history") {
        this.getHistory();
      } else if (key === "upcoming") {
        this.getAppointments();
      }
    },
  },
  computed: {
    haveAppointments() {
      return this.appointment.upcomingAppointments.length > 0;
    },
    haveRecentAppointments() {
      return this.appointment.recentAppointments.length > 0;
    },
  },
};
</script>

<style></style>
