<template>
    <svg v-if="active" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="black"/>
        <path d="M10.4995 10.7574V19.2426M10.4995 19.2426L13.9467 15.7955M10.4995 19.2426L7.0524 15.7955" stroke="white"
              stroke-linecap="round"/>
        <path d="M19.4995 19.2417V10.7564M19.4995 10.7564L22.9467 14.2035M19.4995 10.7564L16.0524 14.2035"
              stroke="white"
              stroke-linecap="round"/>
        <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="black"/>
    </svg>
    <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.4995 10.7574V19.2426M10.4995 19.2426L13.9467 15.7955M10.4995 19.2426L7.0524 15.7955" stroke="black"
              stroke-linecap="round"/>
        <path d="M19.4995 19.2417V10.7564M19.4995 10.7564L22.9467 14.2035M19.4995 10.7564L16.0524 14.2035"
              stroke="black"
              stroke-linecap="round"/>
        <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="black"/>
    </svg>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false
        }
    },
    name: "SortIcon"
}
</script>

<style>

</style>
