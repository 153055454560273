<template>
  <router-link v-if="appointment" :to="`/history/${appointment.code}`">
    <div class="row recentAppointmentsExpertTable">
      <div class="col-lg-4">
        <div class="d-flex align-items-center">
          <avatar
            user-role="employees"
            size="100"
            :image-name="appointment.employee_image"
            :user-code="appointment.employee_code"
            width="60px"
            height="60px"
          />
          <div class="recentAppointmentsExpertTable__nameBlock">
            <div class="recentAppointmentsExpertTable__name">
              {{
                `${appointment.employee_first_name} ${appointment.employee_last_name}`
              }}
            </div>
            <div class="recentAppointmentsExpertTable__position">
              {{ appointment.code }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="recentAppointmentsExpertTable__type">
          {{ appointment.service_name }}
        </div>
        <div class="recentAppointmentsExpertTable__coach">
          {{ appointment.consultation_name }}
        </div>
      </div>
      <div class="col-lg-2 d-flex flex-column">
        <div class="recentAppointmentsExpertTable__time">
          <data-formatted date-format="HH:mm" :data="appointment.time" />
        </div>
        <div class="recentAppointmentsExpertTable__data">
          <data-formatted date-format="dd MMM yyyy" :data="appointment.time" />
        </div>
      </div>
      <div class="col-lg-2">
        <div class="recentAppointmentsExpertTable__coast">
          €{{ appointment.company_coverage }}
        </div>
      </div>
      <div class="col-1 text-end">
        <arrow-think height="20px" width="20px" />
      </div>
    </div>
  </router-link>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import ArrowThink from "@/components/UI/icons/arrows/ArrowThink";
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  props: {
    appointment: {},
  },
  name: "CompanyAppointmentsTable",
  components: { DataFormatted, ArrowThink, Avatar },
};
</script>

<style></style>
