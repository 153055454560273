<template></template>

<script>
export default {
  props: {
    items: [Object, Array],
  },
  name: "Pagination",
};
</script>

<style scoped></style>
