<template>
  <router-link
    :to="/appointment/ + recentAppointment.code"
    class="row recentAppointmentsExpertTable"
  >
    <div class="col-4 d-flex align-items-center">
      <avatar
        user-role="employees"
        size="100"
        :image-name="recentAppointment.employee_image"
        :user-code="recentAppointment.employee_code"
        height="60px"
        width="60px"
      />
      <div class="recentAppointmentsExpertTable__nameBlock">
        <div class="recentAppointmentsExpertTable__name">
          <short-name
            :name="
              recentAppointment.employee_first_name +
              ' ' +
              recentAppointment.employee_last_name
            "
          />
        </div>
        <div class="recentAppointmentsExpertTable__position">
          {{ recentAppointment.employee_code }}
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="recentAppointmentsExpertTable__type">
        {{ recentAppointment.consultation_name }}
      </div>
      <div class="recentAppointmentsExpertTable__coach">
        {{ recentAppointment.service_name }}
      </div>
    </div>
    <div class="col-2 d-flex flex-column">
      <div class="recentAppointmentsExpertTable__time">
        <data-formatted date-format="HH:mm" :data="recentAppointment.time" />
      </div>
      <div class="recentAppointmentsExpertTable__data">
        <data-formatted
          date-format="dd MMM yyyy"
          :data="recentAppointment.time"
        />
      </div>
    </div>
    <div class="col-2 recentAppointmentsExpertTable__coast">
      €{{ recentAppointment.expert_income }}
    </div>
    <div class="col-1 text-end">
      <arrow-think height="20px" width="20px" />
    </div>
  </router-link>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import ShortName from "@/components/components-helpers/ShortName";
import DataDay from "@/components/components-helpers/DataDay";
import ArrowThink from "@/components/UI/icons/arrows/ArrowThink";
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  components: { DataFormatted, ArrowThink, DataDay, ShortName, Avatar },
  props: {
    recentAppointment: Object,
  },
  name: "RecentAppointmentsExpertTable",
};
</script>

<style></style>
