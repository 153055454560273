<template>
  <h1>{{ $t("AppointmentHistory") }}</h1>
  <div class="row justify-content-between align-items-center">
    <div class="employee__recent-sort">
      <div class="mr-15">{{ $t("employeeHomePageSort") }}</div>
      <base-button
        @click="sortBy('date')"
        :active="activeSortBy.date"
        :sort="true"
      >
        {{ $t("filterByDate") }}
      </base-button>
      <base-button
        @click="sortBy('service')"
        :active="activeSortBy.service"
        :sort="true"
      >
        {{ $t("employeeCalendarService") }}
      </base-button>
      <base-button
        @click="sortBy('employee')"
        :active="activeSortBy.employee"
        :sort="true"
      >
        {{ $t("Employee") }}
      </base-button>
      <div class="ml-5 cursor-pointer" @click="changeDirection()">
        <sort-icon :active="directions === 'DESC'" />
      </div>
    </div>
    <div class="response-switcher">
      <div class="me-10">
        {{ $t("view") }}
      </div>
      <table-view-icon
        class="me-10 cursor-pointer"
        :active="view.table"
        @click="changeView('table')"
      />
      <cart-view-icon
        class="me-10 cursor-pointer"
        :active="view.cart"
        @click="changeView('cart')"
      />
    </div>
  </div>
  <div v-if="view.cart" class="row d-flex appointmentHistoryPage">
    <div v-for="appointment in recentAppointments" class="col-6 col-xl-4">
      <recent-appointments
        :is-expert="true"
        :recent-appointment="appointment"
      />
    </div>
  </div>
  <div v-show="view.table" class="appointmentHistoryPage">
    <recent-appointments-expert-table
      v-for="appointment in recentAppointments"
      :recent-appointment="appointment"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/UI/buttons/BaseButton";
import CartViewIcon from "@/components/UI/icons/buttonIcons/CartViewIcon.vue";
import TableViewIcon from "@/components/UI/icons/TableViewIcon";
import RecentAppointments from "@/components/elements/appointment/RecentAppointments";
import RecentAppointmentsExpertTable from "@/components/elements/appointment/RecentAppointmentsExpertTable";
import SortIcon from "@/components/UI/icons/filterIcons/SortIcon";

export default {
  name: "ExpertAppointmentHistory",
  components: {
    SortIcon,
    RecentAppointmentsExpertTable,
    RecentAppointments,
    TableViewIcon,
    CartViewIcon,
    BaseButton,
  },
  data() {
    return {
      recentAppointments: {},
      directions: "ASC",
      activeSort: null,
      activeSortBy: {
        date: true,
        service: false,
        employee: false,
      },
    };
  },
  mounted() {
    this.getHistory("data");
  },
  methods: {
    sortBy(data) {
      Object.keys(this.activeSortBy).forEach((key) => {
        this.activeSortBy[key] = data === key;
      });
      this.activeSort = data;
      this.getHistory();
    },
    changeDirection() {
      this.directions = this.directions === "ASC" ? "DESC" : "ASC";
      this.getHistory();
    },
    changeView(view) {
      let viewValue = {
        cart: false,
        table: false,
      };
      viewValue[view] = true;
      this.$store.commit("SET_VIEW", viewValue);
    },
    getHistory() {
      this.$http
        .get("/appointments/recent", {
          params: {
            sortBy: this.activeSort,
            direction: this.directions,
          },
        })
        .then(({ data }) => {
          this.recentAppointments = data.body;
        });
    },
  },
  computed: {
    ...mapGetters({
      view: "VIEW",
    }),
  },
};
</script>

<style></style>
