<template>
  <div class="row align-items-center justify-content-between">
    <h1 class="w-auto">{{ $t("sidebarAppointmentsBtn") }}</h1>
    <div class="experts__searchInput">
      <search-input
        v-model="search"
        :search-function="searchAppointment"
        @keyup.enter="searchAppointment"
        :placeholder="$t('findEmployeeInputs')"
      />
    </div>
  </div>
  <div class="row companyAppointmentHistory__titlesRow">
    <div class="col-lg-4">{{ $t("Employee") }}</div>
    <div class="col-lg-3">{{ $t("employeeCalendarService") }}</div>
    <div class="col-lg-2">{{ $t("appointmentDate") }}</div>
    <div class="col-lg-2">{{ $t("companyCoverage") }}</div>
    <div class="col-lg-1"></div>
  </div>
  <company-appointments-table
    v-for="appointment in appointments"
    :appointment="appointment"
  />
  <div v-if="appointments.length === 10" class="w-max-content m-auto">
    <base-button class="loadMoreBtn" @click="loadMore()" :load="true"
      >{{ $t("loadMore") }}
    </base-button>
  </div>
</template>

<script>
import SearchInput from "@/components/UI/inputs/SearchInput";
import CompanyAppointmentsTable from "@/components/elements/appointment/CompanyAppointmentsTable";
import BaseButton from "@/components/UI/buttons/BaseButton";

export default {
  name: "CompanyAppointmentHistory",
  components: { BaseButton, CompanyAppointmentsTable, SearchInput },
  data() {
    return {
      search: "",
      appointments: [],
      offset: 0,
      limit: 10,
    };
  },
  mounted() {
    this.getAppointments();
  },
  methods: {
    searchAppointment() {
      this.getAppointments(this.search);
    },
    loadMore() {
      this.limit = this.limit + 10;
      this.getAppointments(this.search);
    },
    getAppointments(searchValue) {
      let search = searchValue ? searchValue : null;
      this.$http
        .get("/appointments/company", {
          params: {
            search: search,
            offset: this.offset,
            limit: this.limit,
          },
        })
        .then(({ data }) => {
          this.appointments = data.body;
        });
    },
  },
};
</script>

<style></style>
