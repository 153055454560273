<template>
  <div class="recentAppointments__card-element">
    <div>
      <div class="align-items-center justify-content-between m-0 d-flex">
        <div class="recentAppointment__data">
          <data-day :data="recentAppointment.time" />
        </div>
        <div class="recentAppointment__time">
          <data-formatted date-format="HH:mm" :data="recentAppointment.time" />
        </div>
      </div>
      <div class="m-0">
        <div class="recentAppointment__type">
          {{ recentAppointment.consultation_name }}
        </div>
        <div class="recentAppointment__title">
          {{ recentAppointment.service_name }}
        </div>
        <div class="recentAppointment__coast">
          €{{
            recentAppointment.expert_income
              ? recentAppointment.expert_income
              : recentAppointment.price
          }}
        </div>
      </div>
      <div class="row m-0 d-flex flex-nowrap align-items-lg-center">
        <avatar
          v-if="loginUser.role_id === 4"
          class="recentAppointment__avatar"
          user-role="experts"
          :image-name="recentAppointment.expert_image"
          :user-code="recentAppointment.expert_code"
          size="100"
          height="60px"
          width="60px"
        />
        <avatar
          v-if="loginUser.role_id === 2"
          class="recentAppointment__avatar"
          user-role="employees"
          :image-name="recentAppointment.employee_image"
          :user-code="recentAppointment.employee_code"
          size="100"
          height="60px"
          width="60px"
        />
        <div class="w-75 pl-10">
          <div class="recentAppointment__mentor-name">
            <short-name
              v-if="recentAppointment.employee_first_name"
              :name="
                recentAppointment.employee_first_name +
                ' ' +
                recentAppointment.employee_last_name
              "
            />
            <short-name
              v-if="recentAppointment.expert_first_name"
              :name="
                recentAppointment.expert_first_name +
                ' ' +
                recentAppointment.expert_last_name
              "
            />
          </div>
          <div
            v-if="recentAppointment.employee_code"
            class="recentAppointment__position"
          >
            {{ recentAppointment.employee_code }}
          </div>
          <div
            v-if="recentAppointment.expert_position"
            class="recentAppointment__position"
          >
            {{ recentAppointment.expert_position }}
          </div>
        </div>
      </div>
    </div>
    <base-button
      v-if="!history && !isExpert"
      @click="rebook(this.recentAppointment)"
      class="recentAppointment__rebook"
    >
      {{ $t("rebookBtn") }}
    </base-button>
    <base-button
      v-else
      :link="/appointment/ + recentAppointment.code"
      class="recentAppointment__rebook"
    >
      {{ $t("viewAppointment") }}
    </base-button>
  </div>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import ShortName from "@/components/components-helpers/ShortName";
import DataDay from "@/components/components-helpers/DataDay";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import BaseButton from "@/components/UI/buttons/BaseButton";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";

export default {
  components: { BaseButton, DataFormatted, DataDay, ShortName, Avatar },
  props: {
    isExpert: false,
    recentAppointment: Object,
    history: {
      type: Boolean,
      default: false,
    },
  },
  name: "RecentAppointments",
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  methods: {
    async rebook(appointment) {
      let experts = await this.$http
        .get("/experts", {
          params: {
            notActivated: this.loginUser.role_id !== 4,
            blocked: true,
          },
        })
        .then(({ data }) => {
          return data.body;
        });
      let expert = experts.filter(
        (expert) => expert.user_id === appointment.expert_id
      )[0];
      if (expert.user.profiles[0].is_blocked) {
        this.toast.warning(
          `${this.$t("rebookBlockExpert")} ${expert.first_name} ${
            expert.last_name
          }.`
        );
        return;
      }
      let languages = await this.$http.get("/languages").then(({ data }) => {
        return data.body;
      });
      let language = languages.filter(
        (language) => language.id === appointment.language_id
      )[0];
      let services = await this.$http.get("/services").then(({ data }) => {
        return data.body;
      });
      services = services.filter(
        (service) => service.id === appointment.service_id
      )[0];
      let consultations = await this.$http
        .get(
          "/consultations/expert/" +
            expert.user_id +
            "/service/" +
            services.id +
            "",
          {}
        )
        .then(({ data }) => {
          return data.body;
        });
      let type = consultations.filter(
        (consultation) => consultation.id === appointment.consultation_id
      )[0];
      const appointmentInformation = {
        expert,
        language,
        services,
        type,
      };
      this.$store.commit("SET_BOOK_DATA", appointmentInformation);
      this.$store.commit("SET_REBOOK", true);
      await this.$router.push("/calendar");
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
