<template>
  <router-link
    :to="/appointment/ + information.code"
    class="row recentAppointmentsExpertTable"
  >
    <div class="col-3 d-flex align-items-center">
      <avatar
        user-role="experts"
        :image-name="information.expert_image"
        :user-code="information.expert_code"
        size="100"
        height="60px"
        width="60px"
      />
      <div class="recentAppointmentsExpertTable__nameBlock">
        <div class="recentAppointmentsExpertTable__name">
          <short-name
            :name="
              information.expert_first_name + ' ' + information.expert_last_name
            "
          />
        </div>
        <div class="recentAppointmentsExpertTable__position">
          {{ information.expert_position }}
        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="recentAppointmentsExpertTable__type">
        {{ information.consultation_name }}
      </div>
      <div class="recentAppointmentsExpertTable__coach">
        {{ information.service_name }}
      </div>
    </div>
    <div class="col-2 d-flex flex-column">
      <div class="recentAppointmentsExpertTable__time">
        <data-formatted date-format="HH:mm" :data="information.time" />
      </div>
      <div class="recentAppointmentsExpertTable__data">
        <data-day :data="information.time" />
      </div>
    </div>
    <div class="col-2 recentAppointmentsExpertTable__coast">
      €{{ information.price }}
    </div>
    <div class="col-2">
      <div
        v-if="information.status_name"
        class="recentAppointmentsEmployeeTable__status"
        :class="{
          'recentAppointmentsEmployeeTable__status-fail': statusState(
            information.status
          ),
        }"
      >
        {{ information.status_name }}
      </div>
    </div>
    <div class="col-1 text-end">
      <arrow-think height="20px" width="20px" />
    </div>
  </router-link>
</template>

<script>
import DataFormatted from "@/components/components-helpers/DataFormatted";
import DataDay from "@/components/components-helpers/DataDay";
import ArrowThink from "@/components/UI/icons/arrows/ArrowThink";
import ShortName from "@/components/components-helpers/ShortName";
import Avatar from "@/components/UI/images/Avatar";
import BaseButton from "@/components/UI/buttons/BaseButton";

export default {
  props: {
    information: Object,
  },
  name: "EmployeeRecentAppointmentsTable",
  components: {
    BaseButton,
    Avatar,
    ShortName,
    ArrowThink,
    DataDay,
    DataFormatted,
  },
  methods: {
    statusState(status) {
      return status === "cancelled" || status === "failed";
    },
  },
};
</script>

<style></style>
